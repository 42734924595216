body {
    background: #f2f2f2;
    font-family: 'Roboto', sans-serif;
}

.clear {
    clear: both;
}

div {
    box-sizing: border-box;
}

h2 {
    color: #575b70;
    font-size: 25px;
    font-weight: 600;
    margin-top: 0;
}

h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: #70727e;
    letter-spacing: 1px;
    margin-top: 0;
}

h5 {
    border-bottom: 1px solid #dedede;
    color: #747476;
    font-size: 13px;
    font-weight: 600;
    padding: 4px 15px 14px 5px;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.close-button {
    width: 26px;
    height: 26px;
    background: url(../images/close.png) no-repeat center;
    display: block;
    position: absolute;
    background-size: 100%;
    opacity: 0.5;
}

.close-button:hover {
    opacity: 1
}

.data-table-block {
    box-shadow: 0 1px 2px #d0d0d0;
    padding: 15px;
    margin-bottom: 35px;
}

.rates-block {
    margin-bottom: 20px;
}

.data-table-block th {
    font-size: 12px;
    font-weight: 700;
    color: #acacac;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
    padding: 17px 0 12px 16px;
}

.data-table-block td {
    width: 30%;
    font-size: 13px;
    padding: 12px 0 12px 16px;
    border-bottom: 1px solid #fff;
}

.data-table-block td.add-option td {
    background: #fdfefe;
}

.data-table-block tr.add-option .add-more {
    width: 80%;
    border: 1px solid #c8caca;
    font-size: 15px;
    border-radius: 5px;
    padding: 6px;
}

.data-table-block tr.add-option td {
    padding: 2px 0 3px 8px
}

.data-table-block tr.add-option .add-more-button {
    background: url(../images/add.png) no-repeat center;
    background-size: 30%;
    display: inline-block;
    width: 50px;
    height: 50px;
}

.edit-row {
    background: url(../images/tool-edit.png) no-repeat center;
    background-size: 50%;
    display: inline-block;
    width: 28px;
    height: 14px;
}

.delete-row {
    background: url(../images/delete.png) no-repeat center;
    background-size: 37%;
    display: inline-block;
    width: 28px;
    height: 14px;
    margin-left: -3px;
}

.data-table-block td.row-controls {
    padding-left: 0;
}

.data-table-block tr.odd {
    background-color: #f8f8f8;
}

.data-table-block tr.even {
    background-color: #fbfcfc;
}

.data-table {
    width: 60%;
}

#supplier-main-block {
    width: 100%;
    background: #fff;
    padding: 40px 30px;
}

.supplier-block {
    background: #fdfdfd;
    border: 1px solid #ddd3d3;
    position: relative;
    margin-bottom: 30px;
}

.supplier-block .field-list,
.supplier-block .field-list li {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 5px;
}

.supplier-block .field-list {
    padding-top: 8px;
}

.supplier-block .field-list li input {
    margin-right: 10px;
}

.supplier-block .edit-block {
    background: #f3f3f3 url(../images/tool-edit.png) no-repeat center;
    background-size: 50%;
    display: block;
    width: 32px;
    height: 32px;
    box-shadow: 0 1px 2px #d0d0d0;
    position: absolute;
    right: 15px;
    top: 9px;
}

.supplier-block .cancel-block {
    background: #f3f3f3 url(../images/cancel.png) no-repeat center;
    background-size: 50%;
    display: block;
    width: 32px;
    height: 32px;
    box-shadow: 0 1px 2px #d0d0d0;
    position: absolute;
    right: 15px;
    top: 9px;
}

.supplier-block .edit-block:hover,
.supplier-block .cancel-block:hover {
    background-color: #faf8f8;
}

.supplier-block .block-title {
    border-bottom: 1px solid #ddd3d3;
    color: #626367;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 25px;
}

.supplier-block .block-content {
    padding: 22px 25px;
}

.inner-description,
.inner-description p {
    font-size: 13px;
    padding-top: 10px;
    font-weight: 400;
    line-height: 20px;
}

.textarea-inner-description {
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Roboto', sans-serif;
    height: 100px;
    padding: 10px;
    box-sizing: border-box;
}

.supplier-block.terms-conditions-details .block-content .supply-col label,
.supplier-block.contact-details .block-content .supply-col label,
.supplier-block.overview-details .block-content .supply-col label {
    font-size: 16px;
    font-weight: 600;
}

.supplier-block.gallery-details .gallery-images {
    padding-top: 20px;
}

.supplier-block.gallery-details .gallery-images .gallery-img {
    float: left;
    width: 24%;
    margin-right: 1%;
}

.supplier-block.gallery-details .gallery-images .gallery-img img {
    width: 100%;
}

.g-other-images:nth-child(4n+1) {
    clear: left;
}

.supplier-block.gallery-details .gallery-images .other img {
    width: 100%;
    height: 129px !important;
}

.supplier-block.gallery-details .image-upload-widget {
    padding-bottom: 20px;
}

.supplier-block.gallery-details .gallery-img {
    position: relative;
}

.supplier-block.gallery-details .gallery-img .close-button {
    width: 24px;
    height: 24px;
    right: 9px;
    top: 12px
}

.terms-conditions-details.terms-accept {
    font-size: 14px;
    font-weight: 600;
}

.terms-conditions-details.terms-accept .accept-terms {
    margin-right: 10px;
}

.supplier-block .block-content .one-third-col {
    width: 33.33%;
    float: left;
}

.supplier-block .block-content .one-two-col {
    width: 50%;
    float: left;
}

.supplier-block .block-content .one-half-col {
    width: 66%;
    float: left;
}

.supplier-block .block-content .supply-col label {
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 5px;
    display: block;
}

.supplier-block .block-content .col-variable {
    font-size: 18px;
    font-weight: 500;
    color: #3e3e42;
}

.supplier-block .block-content .col-variable img {
    max-width: 100%;
}

.supplier-block .block-content .below-label {
    font-size: 11px;
    color: #383838;
    font-weight: 300;
}

.sales-type .supply-col {
    float: left;
    width: 40%;
}

.pax-define .pax-block {
    float: left;
    width: 22%;
}

.supplier-block.sales-details-editable .one-two-col .supply-col.editable-sales-col {
    width: 50%;
    float: left;
}

.supplier-block.sales-details-editable img {
    max-width: 100%;
}

.supplier-block.sales-details-editable .pax-define-block {
    border-top: 1px solid #dcdcdc;
    margin-top: 15px;
    padding-top: 15px;
}

.pax-define-block .pax-age-define .pax-age-col {
    width: 25%;
    display: block;
    padding-top: 15px;
    float: left;
}

.pax-define-block .pax-age-define .pax-age-col .pax-label {
    font-size: 14px;
    padding-bottom: 15px;
}

.pax-define-block .pax-age-define .pax-age-col .pax-label input {
    margin-right: 10px;
}

.pax-define-block .pax-age-define .pax-age-col .pax-select-box {}

.pax-define-block .pax-age-define .pax-age-col .pax-select-box select {
    width: 38%;
    float: left;
    margin-right: 8%;
}

.button-container {
    text-align: right;
}

.button-container .button-apply {
    background: #f7a414;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    padding: 8px 25px;
    box-shadow: 0 1px 2px #d0d0d0;
    cursor: pointer;
}

.button-orange {
    background: #f7a414;
    color: #fff;
    font-size: 19px;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    padding: 12px 28px;
    box-shadow: 0 2px 0px #d0d0d0;
}

.button-grey {
    background: #b0aca4;
    color: #fff;
    font-size: 19px;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    padding: 12px 28px;
    box-shadow: 0 2px 0px #d0d0d0;
}

.button-container .button-apply:hover {
    background: #f5b74b;
}

.supplier-block .rates-availability .availability-intro label {
    font-weight: 400;
}

.rates-availability .availability-intro .avail-name {
    width: 60%;
    float: left;
}

.rates-availability .availability-intro .avail-dates {
    width: 40%;
    float: left;
}

.rates-availability .availability-intro .avail-dates .supply-col {
    width: 50%;
    float: left;
}

.rates-availability .pickup-location {
    border-top: 1px solid #dcdcdc;
    margin-top: 35px;
    padding-top: 30px;
}

.supplier-block.basics-details-editable .tour-category-list .tour-category-list-ul,
.supplier-block.basics-details-editable .tour-category-list .tour-category-list-ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 5px;
}

.supplier-block.basics-details-editable .tour-category-list .tour-category-list-ul li {
    float: left;
    width: 10%;
}

.supplier-block.basics-details-editable .tour-category {
    padding-top: 10px;
}

.supplier-block.basics-details-editable .tour-category label {
    padding-bottom: 10px;
}


/* ediable css*/

.editable {
    box-shadow: 0 0 13px #e0dede;
    position: relative;
}

.editable:before {
    position: absolute;
    background: #6e7b87;
    content: "Editable";
    color: #fff;
    font-size: 10px;
    border-radius: 5px;
    padding: 4px 13px;
    right: 14px;
    top: -14px;
    text-transform: uppercase;
}

.supplier-block.basics-details .details-summary .one-third-col {
    width: 25%;
}

.supplier-block.message-details .step-message .step-img {
    float: left;
    width: 30%;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #f88031;
    padding: 20px 0 0;
}

.supplier-block.message-details .step-message .step-msg {
    float: left;
    width: 70%;
}

.supplier-block.message-details .step-message .step-msg .btn-last-step {
    margin-bottom: 20px;
}

.supplier-block.message-details .step-message .step-msg h6 {
    font-size: 24px;
    margin: 22px 0 21px;
    line-height: 32px;
}

.supplier-block.message-details .step-message .step-msg p {
    padding: 0px 0 10px;
    margin-top: 0;
    line-height: 28px;
}

.supplier-block.introduction-details {
    border: none;
}

.supplier-block.introduction-details .edit-block {
    display: none
}

.supplier-block.introduction-details .intro-steps .step {
    float: left;
    width: 20%;
    text-align: center;
    padding: 0 28px;
    position: relative;
}

.supplier-block.introduction-details .intro-steps .step:before {
    position: absolute;
    background: url(../images/step-arrow.png) no-repeat center;
    width: 29px;
    height: 18px;
    content: "";
    left: -18px;
    top: 68px;
}

.supplier-block.introduction-details .intro-steps .step:first-child:before {
    display: none;
}

.supplier-block.introduction-details .intro-steps .step .step-label {
    color: #9f9f9f;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    padding-bottom: 14px;
}

.supplier-block.introduction-details .intro-steps .step .step-icon {
    width: 100px;
    background: #f7a414;
    height: 100px;
    border-radius: 50px;
    text-align: center;
    padding: 22px 0 0;
    margin: 0 auto;
}

.supplier-block.introduction-details .intro-steps .step .step-desc {
    color: #0a0a0a;
    font-size: 15px;
    font-weight: 300;
    padding-top: 18px;
    line-height: 23px;
}


/* rates */

.rates-block .rate-from {
    width: 10%;
}

.data-table-block .rates-block td.row-controls {
    text-align: right;
    padding-right: 15px;
}

.rates-block td {
    width: auto;
}

.rates-block th.rate-from {
    width: 18%;
}

.rates-block th.rate-to {
    width: 18%;
}

.rates-block th.rate-controls {
    width: 12%;
}

.supplier-block.basics-details .details-summary .one-third-col {
    width: 25%;
}

.supplier-block.message-details .step-message .step-img {
    float: left;
    width: 30%;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #f88031;
    padding: 20px 0 0;
}

.supplier-block.message-details .step-message .step-msg {
    float: left;
    width: 70%;
}

.supplier-block.message-details .step-message .step-msg .btn-last-step {
    margin-bottom: 20px;
}

.supplier-block.message-details .step-message .step-msg h6 {
    font-size: 24px;
    margin: 22px 0 21px;
    line-height: 32px;
}

.supplier-block.message-details .step-message .step-msg p {
    padding: 0px 0 10px;
    margin-top: 0;
    line-height: 28px;
}

.draggable {
    width: 25%;
    height: auto;
}

.draggable:nth-child(4n+1) {
    clear: left;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-isSelected {
    background-color: #f7a414 !important;
}

.cropper {
    height: 400px;
    width: 700px;
}

.cropper img {
    min-width: 100%;
}

.cha-div {
    max-height: 240px;
    overflow-y: scroll;
}