.bk-deit {
    background-color: #fff;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.13);
    margin-top: 20px;
    margin-bottom: 50px;
}

.bk-deit .bk-img {
    width: 150px;
    float: left;
    line-height: 0px;
}

.bk-deit .bk-img img {
    width: 100%;
}

.bk-deit .bk-cont {
    width: 80%;
    float: left;
}

.bk-deit .bk-cont .bk-hed {
    padding-left: 20px;
    padding-top: 17px;
}

.bk-deit .bk-cont h4 {
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    color: #4f4e4e;
    margin: 0px 0px 5px;
}

.bk-deit .bk-cont .bk-typs {
    margin-bottom: 20px;
    padding-left: 20px;
}

.bk-deit .bk-cont .ty {
    background-image: url("../../Img/baseline_today.png");
}

.bk-deit .bk-cont p {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #1b1a1a;
    display: inline-block;
    margin: 0px 20px 0px 0px;
    background-repeat: no-repeat;
    padding-left: 18px;
    background-position: 1px 2px;
}


/* .bk-deit .bk-cont .pc {
    background-image: url("../../img/baseline_beenhere.png");
} */

.bk-deit .bk-cont p {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #1b1a1a;
    display: inline-block;
    margin: 0px 20px 0px 0px;
    background-repeat: no-repeat;
    padding-left: 18px;
    background-position: 1px 2px;
}

.bk-deit .bk-cont p span {
    color: #828282;
}

.bk-deit .bk-cont .bk-tb {
    display: table;
    width: 100%;
}

.bk-deit .bk-cont .bk-tb div {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    color: #2b2929;
    padding: 10px;
    padding-left: 10px;
    display: table-cell;
    border-right: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
}

.bk-deit .bk-cont .bk-tb .duration {
    padding-left: 20px;
}

.bk-deit .bk-cont .bk-tb div span {
    display: block;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #828282;
    margin-bottom: 5px;
}

.bk-deit .bk-cont .bk-tb div {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    color: #2b2929;
    padding: 10px;
    display: table-cell;
    border-right: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    width: 25%;
}

.bk-deit .bk-cont .bk-tb .rate {
    border-right: 0px;
}

.clear {
    clear: both;
    float: none !important;
}

.btn-rw {
    margin-top: 15px;
    margin-bottom: 20px;
}

.btn-rw a {
    background-color: #5a5e60;
}

.bd h4 {
    font-size: 19px;
    color: #4f4e4e;
}

.bd>div {
    background-color: #fff;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.13);
    padding: 15px;
}

.bd>div p {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    color: #828282;
    margin: 0px;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 5px;
}

.bd>div p span {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    float: right;
    display: inline-block;
    color: #2b2a2a;
}

.ct-div h4 {
    font-size: 19px;
    color: #4f4e4e;
}

.ct-div>div.chat-box {
    background-color: #fff;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.13);
    height: 500px;
    position: relative;
}

.ct-div>div.chat-box>a {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    color: rgba(61, 60, 60, 0.58);
    line-height: 1.538;
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 15px;
    display: block;
    text-align: center;
    padding-top: 15px;
}

.ct-div>div.chat-box .cht {
    background-color: #fff;
    margin: 30px 0px;
}

.ct-div>div.chat-box .unrd {
    background-color: #fffbf5;
    padding: 25px 0px 10px;
}

.ct-div .frm {
    background-color: #f9f9f9;
    padding: 20px 30px 13px;
    margin-top: -30px;
}

.ct-div>div.chat-box .cht div {
    background-color: #f7f7f7;
    width: 71%;
    margin: 0 auto 30px;
    border: 1px solid #e9e9e9;
    font-size: 12px;
    font-family: "Roboto";
    color: #242424;
    padding: 15px 25px;
    position: relative;
}

.ct-div>div.chat-box .cht div span {
    position: absolute;
    right: 0px;
    bottom: -20px;
    font-size: 11px;
    font-family: "Roboto";
    color: #a3adbe;
}

.ct-div>div.chat-box .cht div::before {
    content: '';
    position: absolute;
    width: 34px;
    height: 34px;
    background-image: url("../../Img/traveller.png");
    right: -50px;
    top: -1px;
}

.ct-div>div.chat-box .cht div::after {
    content: '';
    background-image: url("../../Img/ar-l.png");
    position: absolute;
    width: 9px;
    height: 18px;
    right: -9px;
    top: 10px;
}

.ct-div>div.chat-box .cht.rit div {
    background-color: #fff;
}

.ct-div>div.chat-box .cht.rit div::before {
    content: '';
    position: absolute;
    width: 34px;
    height: 34px;
    background-image: url("../../Img/traveller.png");
    left: -50px;
    top: -1px;
}

.ct-div>div.chat-box .cht.rit div::after {
    content: '';
    background-image: url("../../Img/ar-l.png");
    position: absolute;
    width: 9px;
    height: 18px;
    left: -9px;
    top: 10px;
}

.ct-div .frm {
    background-color: #f9f9f9;
    padding: 20px 30px 13px;
    margin-top: -30px;
    bottom: 0px;
    position: absolute;
    width: 100%;
}

.ct-div .frm p {
    margin: 0px;
    font-size: 14px;
    font-family: "Roboto";
    color: #4e4d4d;
}

.ct-div .frm form {
    padding: 10px 10px 10px 50px;
    position: relative;
}

.ct-div .frm form textarea {
    border: 0px;
    border-bottom: 2px solid #d9ecea;
    height: 35px;
    width: 100%;
    background-color: transparent;
}

.ct-div .frm form input {
    background-image: url("../../Img/sb-btn.png");
    background-repeat: no-repeat;
    border: 0px;
    background-color: #f49e0a;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-position: 13px 15px;
    box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.11);
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 14px;
}

.ct-div .frm form::after {
    content: '';
    position: absolute;
    width: 34px;
    height: 34px;
    background-image: url("../../Img/traveller.png");
    left: -0px;
    top: 11px;
}

.bd>div div {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #242424;
    line-height: 1.667;
    padding: 10px 5px;
}

.col-l5 {
    width: 40%;
    float: left;
}

.col-l7 {
    width: calc(60% - 25px);
    float: left;
    margin-left: 25px;
}

.pro-info {
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.13);
}

.pro-info>div {
    padding: 25px;
    border-bottom: 1px solid #bcbcbc;
}

.pro-info>div p {
    max-height: 0px;
    transition: all 0.5s;
    overflow: hidden;
    margin: 0px;
    font-size: 14px;
}

.pro-info>div.active p {
    max-height: 300px;
    margin-bottom: 20px
}

.pro-info>div.active {
    background-color: #fafafa
}

.pro-info>div h6 {
    font-size: 18px;
    margin: 0px;
    color: #2b292a;
    font-weight: 400;
}

.pro-info>div.active h6 {
    margin-bottom: 13px;
}

.row-pi {
    margin-top: 30px
}

.rate a {
    background-color: #2a9b59;
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 20px;
    display: block;
    width: 85px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}

.ct-div>div.chat-box .cht.rit div span {
    position: absolute;
    left: 0px;
    bottom: -20px;
}