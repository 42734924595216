
body {
    background: #f2f2f2;
    font-family: 'Roboto', sans-serif;
}
#supplier-main-block {
    width: 1170px;
    padding: 40px 30px;
}
.pax-age-define select {
    width: 100%!important;
    margin-right: 0!important;
}
.pax-select-box .MuiFormControl-root{
    margin-right: 10px;
}
.dr-nam h1 {
    font-size: 35px;
    color: rgb(86, 86, 91);
    font-weight: 700;
    margin-bottom: 8px;
}
.dr-nam span {
    font-size: 14px;
    color: rgb(86, 86, 91);
}
.dr-nam {
    width: 60%;
    float: left;
    margin-left: 20px;
}

.nip{

    margin-top: 50px;


}
.tol-ern {
    width: calc(40% - 72px);
    float: left;
    background-color: #f7a414;
    padding: 20px 30px;
    background-image: url('./../../Img/piggy-bank.png');
    background-repeat: no-repeat;
    background-position: 93% center;
    background-size: 50px;
    margin-left: 1%;
}
.tol-ern h4 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin: 10px 0px 0px;
    font-weight: 400
}
.tol-ern span {
    font-size: 14px;
    color: rgb(255, 255, 255);
}
.acbup>div {
    width: calc(20% - 56px);
    background-color: rgb(250, 251, 251);
    box-shadow: 0px 3px 12.22px 0.78px rgba(0, 0, 0, 0.07);
    float: left;
    margin: 27px;
    padding: 30px 30px;
    
}

.acbup{

    width:100%;
}
.clearfix {
    clear: both;
}
.acbup>div:last-child {
    margin-right: 0px;
}
.acbup>div h5 {
    font-size: 16px;
    color: rgb(86, 86, 91);
    font-weight: 400;
    margin: 0 auto 10px;
}
.acbup>div span {
    display: block;
    font-size: 30px;
    font-weight: 500;
    color: rgb(86, 86, 91);
    display: block;
    
    
}
.acbup {
    margin-top: 20px
}
.bok-sec {
    margin-top: 50px;
}
.bok-sec h3 {
    font-size: 30px;
    color: rgb(86, 86, 91);
    font-weight: 600;
}
.bk-menu {
   
    border-width: 1px;
    border-color: #d8d8d8;
    background-image: -moz-linear-gradient( 90deg, rgb(227, 226, 226) 2%, rgb(242, 242, 242) 97%);
    background-image: -webkit-linear-gradient( 90deg, rgb(227, 226, 226) 2%, rgb(242, 242, 242) 97%);
    background-image: -ms-linear-gradient( 90deg, rgb(227, 226, 226) 2%, rgb(242, 242, 242) 97%);
    display: inline-block;
}
.bk-menu a {
    display: inline-block;
    font-size: 13px;
    color: rgb(86, 86, 91);
    text-decoration: none;
    padding: 10px 30px;
    transition: all 0.5s;
    border-right: 0px solid #d8d8d8;
    position: relative;
    margin-right: 1px;
    font-weight: 500;
     background-color:#eeeeee;
}
.bk-menu a:last-child {
    border-right: 0px;
    border-left:15px;
}
.bk-menu a:hover {
    background-color: #f7a414;
    color: #fff;
}
.bk-menu a.active {
    background-color: #f7a414;
    color: #fff;
}
.bk-mindiv {
    background-color: #fafbfb;
    box-shadow: 0px 3px 12.22px 0.78px rgba(0, 0, 0, 0.07);
    padding: 50px 25px 25px;
}
.bk-menu a span {
    position: absolute;
    left: 10px;
    top: -7px;
    background: #e70a0a;
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    line-height: 14px;
}
.tab-bok table {
    width: 100%;
    border-collapse: collapse;

}
.tab-bok table th {
    font-size: 16px;
    color: bold rgb(86, 86, 91);
    font-weight: bold;
    text-align: left;
    padding: 13px 10px;
    
}
.tab-bok table td {
    font-size: 15px;
    color: rgba(1, 1, 1, 0.769);
    font-weight: 400;
    padding: 13px 10px;
    text-align: left;
}
.tab-bok table td span {
    display: block;
    font-size: 12px;
    font-weight: 300;
}
.tab-bok table tr:nth-child(2n+0) {
    background-color: #f5f6f6
}
.tab-bok table td a.com {
    background-color: #2b9b5a;
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 16px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
}
.tab-bok table td a.vie {
    background: #f7a414;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    text-decoration: none;
    padding: 5px 15px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
}
.tab-bok table th:nth-child(5) {
    text-align: center;
}
.tab-bok table td:nth-child(5) {
    text-align: center;
}
.tab-bok table th:nth-child(1) {
    width: 130px;
    text-align: left;
}
.tab-bok table th:nth-child(2) {
    width: 150px;
}
.tab-bok table th:nth-child(3) {
    width: 100px;
}
.tab-bok table th:nth-child(4) {
    width: 80px;
}
.tab-bok table th:nth-child(5) {
    width: 150px;
}
.tab-bok table th:nth-child(6) {
    width: 60px;
}
.tab-bok table th:nth-child(7) {
    width:60px;
}
.tab-bok table th:nth-child(8) {
    width: 3%;
}

.tab-bok table th:nth-child(9) {
    width: 3%;
}


.tab-bok table td:nth-child(1) {
    width: 130px;
    text-align: left;
}
.tab-bok table td:nth-child(2) {
    width: 150px;
}
.tab-bok table td:nth-child(3) {
    width: 150px;
}
.tab-bok table td:nth-child(4) {
    width: 80px;
}
.tab-bok table td:nth-child(5) {
    width: 150px;
}
.tab-bok table td:nth-child(6) {
    width: 60px;
}
.tab-bok table td:nth-child(7) {
    width: 60px;
}

.tab-bok table td:nth-child(8) {
    width: 3%;
}

.tab-bok table td:nth-child(9) {
    width: 3%;
}

.tab-bok table tr {
    width: 100%;
}
.tab-pan {
    margin-top: 10px;
   

}
.pro-div {
    background-color: #fafbfb;
    box-shadow: 0px 3px 12.22px 0.78px rgba(0, 0, 0, 0.07);
    padding: 30px 40px 25px;
}
.pro-div h5 {
    font-size: 20px;
    color: rgb(1, 1, 1);
    font-weight: 500;
    margin: 0px;
}
.pro-div p {
    font-size: 15px;
    color: rgb(0, 0, 0);
    line-height: 1.579;
    margin-top: 6px;
    font-weight: 400;
}
.profrm-row {
    margin-bottom: 20px;
    position: relative;
}
.profrm-row label {
    display: inline-block;
    width: 190px;
    font-size: 14px;
    font-weight: 400;
    vertical-align: top;
    padding-top: 8px;
}
.profrm-row input {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(202, 200, 200);
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 3.464px 16.92px 1.08px rgba(0, 0, 0, 0.08);
    display: inline-block;
    height: 35px;
    width: 92%;
    padding-left: 10px
}
.profrm-row span {
    background-image: url('../../Img/pencil-edit.png');
    display: block;
    position: absolute;
    right: 5px;
    bottom: 0px;
    background-size: 100%;
    width: 40px;
    height: 39px;
    cursor: pointer;
}
.profrm-div {
    width: 60%;
    float: left;
}
.profrm-div>div:first-child input {
    width: 55%;
}
.profrm-row textarea {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(202, 200, 200);
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 3.464px 16.92px 1.08px rgba(0, 0, 0, 0.08);
    display: inline-block;
    height: 150px;
    width: 69%;
}
.profrm-min {
    margin-top: 45px;
}
.profrm-up {
    width: 40%;
    float: left;
}
.profrm-fil {
    background-color: #f5f5f5;
    background-image: url('./../../Img/placeholder-generic.png');
    background-repeat: no-repeat;
    background-position: center 100px;
    height: 100px;
    width: 75%;
    margin-left: 75px;
    background-size: 160px;
    text-align: center;
    padding-top: 280px;
}
.profrm-fil span {
    display: block;
    margin-top: 50px;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
}
.profrm-fil div {
    position: relative;
}
.profrm-fil div:after {
    content: 'Upload Image';
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    background-color: #777575;
    color: #fff;
    width: 150px;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
}
.profrm-fil input {
    position: absolute;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    left: 0px;
    right: 0px;
    width: 170px;
    margin: 0 auto;
    height: 40px;
}
.pro-hit {
    height: 70px
}
.cp-min>div:first-child input {
    width: 92%;
}
.profrm-btn input {
    background: #f7a414;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    padding: 11px 30px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    border: 0px;
    border-radius: 1px;
    cursor: pointer;
    float: right;
    transition: all 0.5s;
    
}
.profrm-btn input:hover {
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
}
.profrm-btn {
    overflow: hidden;
    margin: 50px auto 15px;
    padding: 5px;
}
.tucat {
    background-color: #fafbfb;
    margin-top: 30px;
    box-shadow: 0px 3px 12.22px 0.78px rgba(0, 0, 0, 0.07);
}
.tucat h5 {
    font-size: 20px;
    color: rgb(1, 1, 1);
    font-weight: 500;
    margin: 0px;
}
.tucat p {
    font-size: 15px;
    color: rgb(0, 0, 0);
    line-height: 1.579;
    margin-top: 6px;
    font-weight: 400;
}
.tucat>div {
    border-bottom: 1px solid #e7e7e7;
    padding: 30px 40px 25px;
}
.tctchek {
    margin-top: 30px;
}
.tctchek label {
    display: inline-block;
    margin-right: 55px;
    font-size: 15px;
}
.tctchek label input {
    margin-right: 10px;
}
.tct-btn {
    float: right;
    margin-top: -10px;
}
.tct-btn input {
    background: #f7a414;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    padding: 11px 30px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    border: 0px;
    border-radius: 1px;
    cursor: pointer;
    float: right;
    transition: all 0.5s;
}
.tctchek-chk {
    float: left;
}
.uplo-mun>div {
    width: calc(25% - 15px);
    margin-right: 20px;
    float: left;
}
.uplo-div {
    background-color: #f5f5f5;
    background-image: url('./../../Img/placeholder-generic.png');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 80px 0px 110px;
    background-size: 90px;
}
.uplo-div div.bt:after {
    content: 'Upload Image';
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    background-color: #777575;
    color: #fff;
    width: 120px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}
.uplo-div div.bt {
    position: relative;
}
.uplo-div div.bt input {
    position: absolute;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    left: 0px;
    right: 0px;
    width: 170px;
    margin: 0 auto;
    height: 40px;
}
.uplo-mun>div:nth-child(4) {
    margin-right: 0px;
}
.uplo-mun {
    margin-top: 35px;
}
.added {
    background-size: cover;
}
.added:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.53);
}
.vop {
    position: absolute;
    z-index: 20;
    right: 20px;
    bottom: 10px;
}
.vop .viw {
    background-image: url('./../../Img/view.png');
    width: 40px;
    height: 35px;
    background-size: 26px;
    display: inline-block;
    border-right: 1px solid #fff;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: 0px center;
    cursor: pointer;
}
.vop .dele {
    background-image: url('./../../Img/cls.png');
    width: 17px;
    height: 35px;
    background-size: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0px center;
    cursor: pointer;
}
.uplo-mun>div p {
    font-size: 14px;
    color: rgb(1, 1, 1);
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.uplo-mun>div span {
    display: block;
    font-size: 11px;
    color: rgb(1, 1, 1);
    font-weight: 100;
}
.acbup>div:nth-child(1) span {
    background-image: url('./../../Img/ongoing_task.png');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 100%;
}
.acbup>div:nth-child(2) span {
    background-image: url('./../../Img/icon_tick.png');
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: 100%;
}
.acbup>div:nth-child(3) span {
    background-image: url('./../../Img/book.png');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: 100%;
}
.acbup>div:nth-child(4) span {
    background-image: url('./../../Img/upcom.png');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: 100%;
}
.acbup>div:nth-child(5) span {
    background-image: url('./../../Img/pending.png');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 100%;
}

.profrm-row:hover span {
    background-image: url('./../../Img/pencil-hov.png');}
.profrm-row.active span {
    background-image: url('./../../Img/pencil-done.png');}